import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner";
import axios from "../axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineArrowUp } from "react-icons/ai";
import Hello from "../assets/hello.png";

const SpecificTrack = () => {
  const { id } = useParams();
  const [singleTrack, setSingleTrack] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTrack = async () => {
    try {
      setLoading(true);

      const response = await axios.get("/tracks/" + id);
      if (response) {
        setLoading(false);
        setSingleTrack([response.data.response]);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Fetching Track.", { theme: "dark" });
      toast.error("Network error", { theme: "dark" });
    }
  };

  useEffect(() => {
    fetchTrack();
  }, []);

  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer blueBg text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}

      {loading ? (
        <div className="w-full h-[80vh] flex justify-center items-center">
          <Spinner message="Loading..." />
        </div>
      ) : (
        <>
          {/* wrapper */}
          <div className="px-[10px] md:px-[2em] xl:px-[5em] mt-[10px] md:mt-[20px] pb-[20px]">
            {/* content */}
            {singleTrack?.length < 1 ? (
              <div className="h-[80vh] w-full flex justify-center items-center">
                <div className="">
                  <p>🫷🏾Something's Up🫸🏾</p>
                  <p>Looks Like This Track Was Not Fetched</p>
                  <h4>Possible Reasons</h4>
                  <div>
                    <p>1. Make sure your internet is Good</p>
                    <p>
                      2. If network is good, then this track no longer exists
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div>
                  {singleTrack?.map((item) => (
                    <div key={item._id}>
                      <div className="mt-[10px] flex flex-col sm:flex-row justify-between">
                        <h2>{item.title}</h2>
                      </div>

                      <div className="w-full flex justify-center mt-3">
                        <a
                          href="https://kepsa-dseap.azurefd.net/"
                          target="_blank"
                          rel="noreferrer"
                          className="blueBg text-white px-4 py-2 rounded-mdd"
                        >
                          Start Learning
                        </a>
                      </div>

                      <div className=" pt-[2em] sm:pt-[4em] w-[100%]  sm:w-[80%] m-auto">
                        <div className="flex justify-center mb-5">
                          <img
                            src={item.photo}
                            alt=""
                            loading="lazy"
                            className="max-h-[500px] object-contain rounded-md"
                          />
                        </div>

                        <p className="mt-[10px] text-center">
                          {item.description}
                        </p>

                        <div className="w-full flex justify-center mb-4">
                          <a
                            href="https://kepsa-dseap.azurefd.net/"
                            target="_blank"
                            rel="noreferrer"
                            className="blueBg text-white px-4 py-2 rounded-mdd"
                          >
                            Start Learning
                          </a>
                        </div>

                        {/* videos */}

                        {item.youTubeLinks?.length > 0 && (
                          <>
                            {item.youTubeLinks.map((vid) => (
                              <div
                                key={vid._id}
                                className="flex flex-col justify-center items-center mb-5"
                              >
                                <iframe
                                  className="w-full"
                                  height="514"
                                  src={vid.link}
                                  // title="What is Microsoft&#39;s approach to AI?"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  referrerPolicy="strict-origin-when-cross-origin"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            ))}
                          </>
                        )}

                        <div className="w-full flex justify-center mb-3">
                          <a
                            href="https://kepsa-dseap.azurefd.net/"
                            target="_blank"
                            rel="noreferrer"
                            className="blueBg text-white px-4 py-2 rounded-mdd"
                          >
                            Start Learning
                          </a>
                        </div>

                        {/* other links */}
                        <div>
                          {item.textLinks?.length > 0 && (
                            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-5">
                              {item.textLinks.map((acc, idx) => (
                                <div key={acc._id}>
                                  <div className="flex justify-center">
                                    <img
                                      src={Hello}
                                      alt=""
                                      className="max-h-[75px] object-cover"
                                    />
                                  </div>
                                  <div className="flex justify-center mt-1">
                                    <a
                                      href={`${acc.link}`}
                                      target="_blank"
                                      className="text-sm underline "
                                    >
                                      Additional Info {idx + 1}
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SpecificTrack;
