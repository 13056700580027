import { useState } from "react";
import axios from "../../axios";
import { toast } from "react-toastify";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";

const YoutTubeLinks = ({ item }) => {
  // link
  const [link, setLink] = useState("");
  const [loadingLink, setLoadingLink] = useState(false);

  const handleAddLink = async (youtube) => {
    try {
      if (!link) {
        toast.error("Link cannot be empty", { theme: "dark" });
        return;
      }
      setLoadingLink(true);
      let id = youtube._id;
      let linkData = { link };
      // add link
      await axios.post("/tracks/youtube/" + id, linkData);
      setLoadingLink(false);
      setLink("");
      window.location.reload();
    } catch (error) {
      setLoadingLink(false);
      toast.error("Failed To Add Link");
    }
  };

  const handleDeleteLink = async (youTubeLinkId) => {
    if (!youTubeLinkId) return;
    try {
      let id = item._id;

      const response = await axios.delete(
        `/tracks/youtube/${id}/${youTubeLinkId}`
      );
      if (response) {
        toast.success("deleted");
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed");
    }
  };

  return (
    <div className="text-white">
      <form onSubmit={() => handleAddLink(item)}>
        <div>
          <label htmlFor="link" className="flex items-center gap-8">
            <p className="text-lg font-bold">Add A YouTube Link</p>
          </label>
        </div>
        <div className="flex items-center pt-[1px] w-[100%]  gap-[10px] ">
          <textarea
            name="link"
            id="link"
            cols="30"
            rows="1"
            placeholder="Enter link"
            className="w-[100%] bg-transparent p-[8px] outline-none border-none rounded-md"
            style={{ border: "1px solid #5e5d5d" }}
            required
            value={link}
            onChange={(e) => setLink(e.target.value)}
          ></textarea>

          <button
            className="cursor-pointer"
            onClick={() => handleAddLink(item)}
          >
            {loadingLink ? (
              <p className="bg-orange-800 text-zinc-200 p-[8px] rounded-lg">
                wait...
              </p>
            ) : (
              <p className="bg-pink-700 p-[8px] text-white rounded-lg">Add</p>
            )}
          </button>
        </div>
      </form>
      {/* show all sent links */}
      <div className="mt-[20px] p-[5px] rounded-lg">
        {/* fetch links from latest to earliest */}
        {item.youTubeLinks?.length >= 1 ? (
          <div className="border border-zinc-200 rounded-lg">
            {[...item.youTubeLinks].reverse().map((item, index) => (
              <div
                key={item._id}
                className="mb-[5px]  p-2 rounded-xl"
                style={{}}
              >
                <div className="flex mb-[3px] items-center justify-between">
                  <div className="flex items-center gap-[20px]">
                    <p className="text-zinc-400 text-sm">
                      {moment(item.createdAt).fromNow()}
                    </p>
                  </div>

                  <p>
                    <AiOutlineDelete
                      className="text-2xl text-red-500 cursor-pointer"
                      onClick={() => handleDeleteLink(item._id)}
                    />
                  </p>
                </div>

                <div className=" my-1">
                  <p style={{ wordWrap: "break-word" }}>{item.link}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-[10px] text-gray-400">
            <p>No Links Yet </p>
          </div>
        )}
      </div>
      {/*  */}

      {/*  */}
    </div>
  );
};

export default YoutTubeLinks;
